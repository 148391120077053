import React, { useState } from 'react';
import { Navbar, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Navigation.scss'; // Add your styling if needed

function Navigation() {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/'); // Navigate to the homepage
  };

  const handlePricingClick = () => {
    setShowModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Hide the modal
  };

  return (
    <>
      <Navbar bg="light" expand="lg" className="custom-navbar">
        <Navbar.Brand onClick={handleLogoClick} className="navbar-brand-custom">
          SwiftPhotoAI
        </Navbar.Brand>
        <Navbar.Toggle />

        {/* Right-aligned Sign Up button */}
        <Navbar.Collapse className="justify-content-end">
          <Button
            variant="outline-primary"
            className="btn-custom"
            onClick={handlePricingClick}
          >
            Pricing
          </Button>
        </Navbar.Collapse>
      </Navbar>

      {/* Modal for Free Access message */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>SwiftPhotoAI is currently free to use!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Subscriptions will be introduced soon, but for now, feel free to explore and create amazing photos!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Navigation;
