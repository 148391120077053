import React, { useEffect } from 'react';
import { Container, Row, Col, Navbar, Button } from 'react-bootstrap';
import Slider from 'react-slick';
import './Home.scss';
import targetImages from '../utils/targetImages';
import { Link, useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();

  // Log page view on component mount
  useEffect(() => {
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleImageClick = (category) => {
    navigate(`/form/${category}`);
  };

  const handleTryNowClick = () => {
    navigate(`/form`);
  };

  const selectedImages = [
    ...targetImages.superheros.slice(0, 3).map((img) => ({ src: img, category: 'faceswap' })),
    ...targetImages.professionals.slice(0, 5).map((img) => ({ src: img, category: 'object_removal' })),
  ];

  return (
    <>
      <header className="text-center my-5">
        <h1 className="header-title">Create Your First Portrait</h1>
        <p className="header-subtitle">
          Get started with AI-powered photo editing, including face swapping and more!
        </p>
        <Button variant="outline-success" className="btn-custom" onClick={handleTryNowClick}>
          Try Now
        </Button>
      </header>

      <Container>
        <Row className="text-center">
          <Col>
            <h2 className="section-title">Let the Journey Begin</h2>
            <p className="section-subtitle">
              Explore endless possibilities for who you can become!
            </p>
          </Col>
        </Row>

        <Slider {...sliderSettings} className="custom-slider">
          {selectedImages.map((image, index) => (
            <div key={index} onClick={() => handleImageClick(image.category)}>
              <img src={image.src} alt={`Image ${index + 1}`} className="carousel-img" />
            </div>
          ))}
        </Slider>

        {/* Features Section */}
        <section className="features-section my-5">
          <Row className="text-center">
            <Col>
              <h2 className="section-title">App Features</h2>
              <p className="section-subtitle">
                Discover the powerful tools we offer to enhance your photos.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={4} sm={6} xs={12} className="mb-4">
              <FeatureCard
                title="Face Swap"
                description="Easily swap faces in your photos with our advanced AI technology."
                link="/form/faceswap"
              />
            </Col>
            <Col md={4} sm={6} xs={12} className="mb-4">
              <FeatureCard
                title="Object Removal"
                description="Remove unwanted objects from your images seamlessly."
                link="/form/object_removal"
              />
            </Col>
          </Row>
        </section>

        <section className="text-center my-5">
          <h2 className="section-title">Explore Trending Sets</h2>
        </section>

        {Object.keys(targetImages)
          .filter((category) => category !== 'features')
          .map((category) => (
            <ImageSection
              key={category}
              title={capitalize(category)}
              images={targetImages[category]}
            />
          ))}
      </Container>

      <footer className="text-center mt-5 custom-footer">
        <p>© 2024 SwiftPhotoAI. All rights reserved.</p>
      </footer>
    </>
  );
}

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

// Updated FeatureCard Component with Modern Buttons
function FeatureCard({ title, description, link}) {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(link);
  };

  return (
    <div className="feature-card" onClick={handleCardClick}>
      <h3>{title}</h3>
      <p>{description}</p>
      <Button
        variant="outline-primary"
        className="modern-button"
        onClick={handleCardClick}
      >
        {title}
      </Button>
    </div>
  );
}

function ImageSection({ title, images }) {
  const navigate = useNavigate();

  const handleImageClick = () => {
    navigate(`/form/${title.toLowerCase()}`);
  };

  return (
    <section className="image-section my-5">
      <div className="section-header d-flex justify-content-between align-items-center">
        <h2>{title}</h2>
        <Link to={`/gallery/${title.toLowerCase()}`} className="view-all-btn">
          View All
        </Link>
      </div>
      <div className="image-grid">
        {images.slice(0, 8).map((image, index) => (
          <div key={index} className="image-card" onClick={() => handleImageClick()}>
            <img src={image} alt={`${title} ${index + 1}`} className="section-image" />
          </div>
        ))}
      </div>
    </section>
  );
}

export default Home;
