// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Navbar */
.custom-navbar {
  padding-left: 5%;
  padding-right: 5%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.navbar-brand-custom {
  font-weight: bold;
  font-size: 1.5rem;
  color: #28a745;
}

.btn-custom {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 0.6rem 1.5rem;
}
.btn-custom:hover {
  background-color: rgb(30.1449275362, 125.8550724638, 52);
}`, "",{"version":3,"sources":["webpack://./src/components/Navigation/Navigation.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA,WAAA;AACA;EACI,gBAAA;EACA,iBAAA;EACA,4CAAA;AADJ;;AAIE;EACE,iBAAA;EACA,iBAAA;EACA,cCXY;ADUhB;;AAIE;EACE,yBCfY;EDgBZ,YAAA;EACA,YAAA;EACA,sBAAA;AADJ;AAEI;EACE,wDAAA;AAAN","sourcesContent":["@import './../../styles/variables.scss';\n\n/* Navbar */\n.custom-navbar {\n    padding-left: 5%;\n    padding-right: 5%;\n    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);\n  }\n  \n  .navbar-brand-custom {\n    font-weight: bold;\n    font-size: 1.5rem;\n    color: $primary-color;\n  }\n  \n  .btn-custom {\n    background-color: $primary-color;\n    color: white;\n    border: none;\n    padding: 0.6rem 1.5rem;\n    &:hover {\n      background-color: darken($primary-color, 10%);\n    }\n  }","// Define primary and secondary colors\n$primary-color: #28a745; // Primary color (e.g., for buttons, links)\n$secondary-color: #007bff; // Secondary color (e.g., for hover states, background highlights)\n\n// Additional variables\n$text-color: #333;\n$light-text-color: #6c757d;\n$dark-background: #1e1e1e;\n$light-background: #f9f9f9;\n$hover-color: #0056b3;\n$border-color: #ddd;\n$image-border-color:#0056b3;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
